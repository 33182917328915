import {
    assessmentPlaceholderHeaderData,
    assessmentPlaceholderTableData,
    AssessmentPlaceholderTableRow,
} from './AssessmentPlaceholder.settings';

import { Button, Typography } from '@speeki/global-ui-components';
import { DefaultTable } from '@speeki/global-ui-components';

import { CompletedAssessments } from '@components/organisms/CompletedAssessments/CompletedAssessments';
import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    ContentHeader,
    ContentHeaderText,
    ContentWrapper,
    HeaderActions,
    PlaceholderSection,
} from '@pages/StaticPlaceholder.styled';

import { TableWrapper } from './AssessmentPlaceholder.styled';

export const AssessmentPlaceholder = () => {
    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgPulseSurvey',
                title: 'Assessments',
            }}
        >
            <ContentWrapper>
                <ContentHeader>
                    <ContentHeaderText>
                        Get your ESG initiatives assessed against international
                        best practices and demonstrate your organisation’s
                        commitment.
                    </ContentHeaderText>
                    <HeaderActions>
                        <Button
                            onClick={() =>
                                (window.location.href =
                                    'https://assessments.speeki.com/landing')
                            }
                            testId="Fe-jdgA"
                            variant="secondary"
                        >
                            Request an assessment
                        </Button>
                        <Button testId="MI2R1n9" variant="primary">
                            Upload Assessment result
                        </Button>
                    </HeaderActions>
                </ContentHeader>

                <PlaceholderSection gap={'32px'}>
                    <Typography.TextH3_24_34>
                        Completed assessments
                    </Typography.TextH3_24_34>

                    <CompletedAssessments />
                </PlaceholderSection>

                <TableWrapper>
                    <Typography.TextH3_24_34>
                        Available assessments
                    </Typography.TextH3_24_34>
                    <div>
                        <DefaultTable<AssessmentPlaceholderTableRow>
                            columns={assessmentPlaceholderHeaderData}
                            data={assessmentPlaceholderTableData}
                            pagination={{
                                options: {
                                    defaultPageSize: 7,
                                    totalCount:
                                        assessmentPlaceholderTableData.length,
                                },
                            }}
                            tableRow={{
                                // eslint-disable-next-line no-console
                                onRowClick: () => console.log('onClick'),
                                showRightArrow: true,
                                testId: 'LdwG0X6',
                            }}
                            tableState={{
                                hiddenColumns: ['id'],
                            }}
                            testId="_vBORVp"
                        />
                    </div>
                </TableWrapper>
            </ContentWrapper>
        </PlaceholderLayout>
    );
};
