import { useState } from 'react';

import {
    libraryPlaceholderHeaderData,
    libraryPlaceholderTableData,
    LibraryPlaceholderTableRow,
} from './LibraryPlaceholder.settings';

import { DefaultTable, SearchField } from '@speeki/global-ui-components';

import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    ContentHeader,
    ContentHeaderText,
    ContentWrapper,
} from '@pages/StaticPlaceholder.styled';

import { SearchFieldWrapper } from './LibraryPlaceholder.styled';

export const LibraryPlaceholder = () => {
    const [searchValue, setSearchValue] = useState('');

    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgLibrary',
                title: 'Library',
            }}
        >
            <ContentWrapper gap={'48px'}>
                <ContentHeader>
                    <ContentHeaderText>
                        Explore ESRS, GRI Standards, CSRD and relevant
                        collection of educational materials, from in-depth
                        guides to interactive tutorials.
                    </ContentHeaderText>
                    <SearchFieldWrapper>
                        <SearchField
                            onChange={setSearchValue}
                            onReset={() => {
                                setSearchValue('');
                            }}
                            onSearch={() => {}}
                            placeholder={'Search...'}
                            testId="InDosZ_"
                            value={searchValue}
                        />
                    </SearchFieldWrapper>
                </ContentHeader>
                <div>
                    <DefaultTable<LibraryPlaceholderTableRow>
                        columns={libraryPlaceholderHeaderData}
                        data={libraryPlaceholderTableData}
                        pagination={{
                            options: {
                                defaultPageSize: 6,
                                totalCount: libraryPlaceholderTableData.length,
                            },
                        }}
                        tableRow={{
                            onRowClick: () => {},
                            showRightArrow: true,
                            testId: '0By3T6Z',
                        }}
                        tableState={{
                            hiddenColumns: ['id'],
                        }}
                        testId="1tFwSd4"
                    />
                </div>
            </ContentWrapper>
        </PlaceholderLayout>
    );
};
