import { Typography } from '@speeki/global-ui-components';

import { Star } from '@assets/svgs/Star';

import { RatingWrapper, StarsWrapper } from './Rating.styled';

export interface RatingProps {
    fill: string;
    percentage: number;
    value: number;
}

const DEFAULT_FILL = '#DFE1E7';

export const Rating = ({ fill, percentage, value }: RatingProps) => {
    return (
        <RatingWrapper>
            <StarsWrapper>
                {Array(5)
                    .fill(0)
                    .map((_, index) => (
                        <Star
                            fill={index < value ? fill : DEFAULT_FILL}
                            key={index}
                        />
                    ))}
            </StarsWrapper>
            <Typography.TextBody_12_20>{percentage}%</Typography.TextBody_12_20>
        </RatingWrapper>
    );
};
