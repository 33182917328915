import { ReactNode } from 'react';

import {
    BoldText,
    PlaceholderCardDescription,
    PlaceholderCardGrayText,
    PlaceholderCardTitle,
    PlaceholderCardWrapper,
    RedText,
} from './PlaceholderCard.styled';

export interface PlaceholderCardProps {
    children: ReactNode;
    hidden?: boolean;
}

export const PlaceholderCard = ({ children, hidden }: PlaceholderCardProps) => {
    return (
        <PlaceholderCardWrapper hidden={hidden}>
            {children}
        </PlaceholderCardWrapper>
    );
};

PlaceholderCard.Title = PlaceholderCardTitle;
PlaceholderCard.Description = PlaceholderCardDescription;
PlaceholderCard.GrayText = PlaceholderCardGrayText;
PlaceholderCard.BoldText = BoldText;
PlaceholderCard.RedText = RedText;
