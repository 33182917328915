import styled from 'styled-components';

export const RatingWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
`;
export const StarsWrapper = styled.div`
    display: flex;
`;
