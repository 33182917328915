import styled, { css } from 'styled-components';

export const DefaultTextButton = styled.button`
    ${({ theme: { font, fontSize, palette, radius } }) => css`
        align-items: center;
        background: ${palette.tertiary.background};
        border-radius: ${radius[4]}px;
        border: 0;
        color: ${palette.text.primary};
        cursor: pointer;
        display: flex;
        font-family: ${font.primary};
        font-size: ${fontSize[14]}px;
        gap: 8px;
        height: 38px;
        letter-spacing: -0.015em;
        line-height: 20px;
        outline: none;
        padding: 0 16px;
    `}
`;
