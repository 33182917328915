import adminTip1 from '/public/images/tips/OBAT1.png';
import adminTip2 from '/public/images/tips/OBAT2.png';
import adminTip3 from '/public/images/tips/OBAT3.png';
import adminTip4 from '/public/images/tips/OBAT4.png';
import adminTip5 from '/public/images/tips/OBAT5.png';
import adminTip6 from '/public/images/tips/OBAT6.png';
import userTip1 from '/public/images/tips/OBUT1.png';
import userTip2 from '/public/images/tips/OBUT2.png';
import userTip3 from '/public/images/tips/OBUT3.png';
import userTip4 from '/public/images/tips/OBUT4.png';
import userTip5 from '/public/images/tips/OBUT5.png';

import { SliderTip } from '@speeki/client-admin';

export const userTips: SliderTip[] = [
    {
        alt: 'dashboard tips',
        image: userTip1,
    },
    {
        alt: 'dashboard tips',
        image: userTip2,
    },
    {
        alt: 'dashboard tips',
        image: userTip3,
    },
    {
        alt: 'incident information tips',
        image: userTip4,
    },
    {
        alt: 'incident activity tips',
        image: userTip5,
    },
];

export const adminTips: SliderTip[] = [
    {
        alt: 'dashboard tips',
        image: adminTip1,
    },
    {
        alt: 'dashboard tips',
        image: adminTip2,
    },
    {
        alt: 'dashboard tips',
        image: adminTip3,
    },
    {
        alt: 'incident information tips',
        image: adminTip4,
    },
    {
        alt: 'incident activity tips',
        image: adminTip5,
    },
    {
        alt: 'manage incident tips',
        image: adminTip6,
    },
];
