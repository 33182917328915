import { RouterProvider } from 'react-router-dom';
import { client } from 'src/boot/apolloClient';

import { AppLoading } from './AppLoading';
import { LocalDevelopment } from './LocalDevelopment';

import { HighchartsProvider } from '@speeki/charts';
import { FeatureFlagsProvider } from '@speeki/feature-flags';
import { WebThemeProvider } from '@speeki/theme';

import { GlobalStyle } from '@components/ions/global.styled';

import { router } from '@services/RouteService/router';

import { ApolloProvider } from '@apollo/client';

export const AppInitializer = () => (
    <LocalDevelopment>
        <FeatureFlagsProvider>
            <ApolloProvider client={client}>
                <WebThemeProvider variant={'default'}>
                    <HighchartsProvider />
                    <GlobalStyle />
                    <AppLoading>
                        <RouterProvider router={router} />
                    </AppLoading>
                </WebThemeProvider>
            </ApolloProvider>
        </FeatureFlagsProvider>
    </LocalDevelopment>
);
