import { nanoid } from 'nanoid';
import { ReactElement } from 'react';
import { Column } from 'react-table';

import { IconManager } from '@speeki/icons';

import { TableBlueText } from '@pages/StaticPlaceholder.styled';

import {
    IconWrapper,
    StatusColor,
    StatusWrapper,
} from './LibraryPlaceholder.styled';

export interface LibraryPlaceholderTableRow {
    dateAndTime: string;
    duration: string;
    icon?: ReactElement;
    id: string;
    location: string;
    status: {
        color: string;
        type: string;
    };
    workshopName: string;
    workshopType: string;
}

export const libraryPlaceholderHeaderData: Column<LibraryPlaceholderTableRow>[] =
    [
        {
            Cell: ({ value }) => <TableBlueText>{value}</TableBlueText>,
            Header: 'Workshop name',
            accessor: 'workshopName',
            width: 280,
        },
        {
            Header: 'Date and time',
            accessor: 'dateAndTime',
        },
        {
            Header: 'Workshop type',
            accessor: 'workshopType',
        },
        {
            Header: 'Location',
            accessor: 'location',
        },
        {
            Header: 'Duration',
            accessor: 'duration',
        },
        {
            Cell: ({ value }) => (
                <StatusWrapper>
                    <StatusColor color={value.color} />
                    <div>{value.type}</div>
                </StatusWrapper>
            ),
            Header: 'Status',
            accessor: 'status',
        },
        {
            Cell: ({ value }) => <IconWrapper>{value}</IconWrapper>,
            Header: '',
            accessor: 'icon',
            disableSortBy: true,
            width: 1,
        },
    ];

const icons = {
    externalLink: (
        <IconManager
            icon="SvgExternalLink24"
            size={24}
            themeColors={{
                default: {
                    'color-1': 'tertiary.default',
                },
                hover: {
                    'color-1': 'tertiary.hover',
                },
            }}
        />
    ),
    notification: (
        <IconManager
            icon="SvgNotification24"
            size={24}
            themeColors={{
                default: {
                    'color-1': 'tertiary.default',
                },
                hover: {
                    'color-1': 'tertiary.hover',
                },
            }}
        />
    ),
};

export const libraryPlaceholderTableData: LibraryPlaceholderTableRow[] = [
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '1 hour',
        icon: icons.externalLink,
        location: 'London',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'Materiality assessments - an essential part of your ESG and sustainability strategy',
        workshopType: 'Online',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Syncing your ESG programme across the business: five tips for building ESG into your organisation',
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '2 days',
        icon: icons.notification,
        location: 'Webinar',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName: `The current state of ESG reporting: what companies are required to report on and what's not.`,
        workshopType: '1-On-1',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '4 hours',
        icon: undefined,
        location: 'Zoom (use zoom if type is 1-on-1)',
        status: {
            color: '#FF3131',
            type: 'Closed',
        },
        workshopName:
            'An update on global ESG reporting obligations and what they mean for your company',
        workshopType: 'Half-Day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, August 1, 2023',
        duration: '1 day',
        icon: icons.notification,
        location: 'Paris',
        status: {
            color: '#0032F5',
            type: 'Upcoming',
        },
        workshopName:
            'Introducing new functionality: Manage the progress of your ESG programmes with the ESG Profile',
        workshopType: 'Full day',
    },
    {
        id: nanoid(),
        dateAndTime: 'Monday, June 2, 2023',
        duration: '2 days',
        icon: icons.externalLink,
        location: 'Frankfurt',
        status: {
            color: '#00EC7D',
            type: 'Open for registration',
        },
        workshopName:
            'ESG metrics: how to measure your ESG Programme and prove its value to stakeholders',
        workshopType: '2-Day',
    },
];
