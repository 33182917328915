import styled, { css } from 'styled-components';

import { Skeleton } from '@speeki/global-ui-components';

export const StatsFrameWrapper = styled.div<{ isDisabled?: boolean }>(
    ({ isDisabled, theme: { palette, radius } }) => css`
        align-items: center;
        background: ${palette.tertiary.background};
        border-radius: ${radius[4]}px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        height: 140px;
        padding: 40px 8px 0;
        text-align: center;

        ${isDisabled &&
        css`
            h3,
            p {
                color: ${palette.text.assistive};
            }
        `}
    `,
);

export const ValueSkeleton = styled(Skeleton)`
    width: 100%;
`;
