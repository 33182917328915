import styled, { css } from 'styled-components';

export const LegendItemWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;

export const LegendOrnament = styled.div<{ fill: string }>(
    ({ fill }) => css`
        background-color: ${fill};
        border-radius: 4px;
        height: 16px;
        width: 16px;
    `,
);
