import {
    Button,
    ChartCard,
    SelectButton,
    Typography,
} from '@speeki/global-ui-components';

import CompletionRate from '@assets/svgs/CompletionRate.svg';
import CompletionTimeAverage from '@assets/svgs/CompletionTimeAverage.svg';
import SatisfactoryRatings from '@assets/svgs/SatisfactoryRatings.svg';

import { CardLabel } from '@components/atoms/CardLabel/CardLabel';
import { LegendItem } from '@components/atoms/LegendItem/LegendItem';
import { LearnerEngagementChart } from '@components/molecules/LearnerEngagementChart/LearnerEngagementChart';
import { Rating } from '@components/molecules/Rating/Rating';
import { TrainingCard } from '@components/molecules/TrainingCard/TrainingCard';
import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    ContentHeader,
    ContentHeaderText,
    ContentWrapper,
} from '@pages/StaticPlaceholder.styled';

import {
    CardsWrapper,
    ChartLegendWrapper,
    ChartWrapper,
    LabelsWrapper,
    LabelText,
    LegendWrapper,
    RatingsWrapper,
    TrainingActions,
} from './TrainingPlaceholder.styled';

export const TrainingPlaceholder = () => {
    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgTraining',
                title: 'Training',
            }}
        >
            <ContentWrapper gap={'48px'}>
                <ContentHeader>
                    <ContentHeaderText>
                        Here is the status of your ESG and Sustainability
                        e-learning initiatives.
                    </ContentHeaderText>
                    <TrainingActions>
                        <Button
                            iconProps={{
                                icon: 'SvgCog24',
                                size: 24,
                            }}
                            size="small"
                            testId="-Z9uecu"
                            variant="tertiary"
                        >
                            Manage content and ESG tags
                        </Button>
                        <Button
                            iconProps={{
                                icon: 'Svg2DManageLearners24',
                                size: 24,
                            }}
                            size="small"
                            testId="zp4y7oh"
                            variant="tertiary"
                        >
                            Manage learners
                        </Button>
                        <Button testId="ikGwxaj" variant="primary">
                            Assign course
                        </Button>
                    </TrainingActions>
                </ContentHeader>

                <div>
                    <ContentHeader>
                        <Typography.TextH3_24_34>
                            Dashboard
                        </Typography.TextH3_24_34>
                        <SelectButton
                            iconProps={{
                                icon: 'SvgFilter24',
                                size: 24,
                            }}
                            testId="ukt7K6z"
                        >
                            Filters
                        </SelectButton>
                    </ContentHeader>

                    <CardsWrapper>
                        <TrainingCard
                            chart={CompletionRate}
                            title="Completion Rate"
                        >
                            <LegendWrapper>
                                <LegendItem fill="#2177FF">
                                    completed courses
                                </LegendItem>
                                <LegendItem fill="#C1C7D2">
                                    assigned courses
                                </LegendItem>
                            </LegendWrapper>
                        </TrainingCard>
                        <TrainingCard
                            chart={CompletionTimeAverage}
                            title="Completion Time Average"
                        >
                            <Typography.TextBody_12_20>
                                Learners completion time on an average
                            </Typography.TextBody_12_20>
                        </TrainingCard>
                        <TrainingCard
                            chart={SatisfactoryRatings}
                            title="Satisfactory Ratings"
                        >
                            <RatingsWrapper>
                                <Rating
                                    fill="#0D3066"
                                    percentage={50}
                                    value={5}
                                />
                                <Rating
                                    fill="#6497E9"
                                    percentage={20}
                                    value={4}
                                />
                                <Rating
                                    fill="#7AADFF"
                                    percentage={15}
                                    value={3}
                                />
                                <Rating
                                    fill="#7AADFF"
                                    percentage={15}
                                    value={2}
                                />
                            </RatingsWrapper>
                        </TrainingCard>
                    </CardsWrapper>

                    <ChartCard
                        chart={
                            <ChartWrapper>
                                <LearnerEngagementChart />
                                <ChartLegendWrapper>
                                    <LegendItem fill="#2177FF">
                                        active learners
                                    </LegendItem>
                                    <LegendItem fill="#C1C7D2">
                                        assigned learners
                                    </LegendItem>
                                </ChartLegendWrapper>
                            </ChartWrapper>
                        }
                        gap="34px"
                        header={{
                            headerComponent: (
                                <LabelsWrapper>
                                    <CardLabel
                                        iconProps={{
                                            icon: 'Svg2DActiveLearners24',
                                            size: 24,
                                        }}
                                    >
                                        <LabelText>
                                            Active Learners: <span>145</span>
                                        </LabelText>
                                    </CardLabel>
                                    <CardLabel
                                        iconProps={{
                                            icon: 'Svg2DAsignedLearners24',
                                            size: 24,
                                        }}
                                    >
                                        <LabelText>
                                            Assigned Learners: <span>450</span>
                                        </LabelText>
                                    </CardLabel>
                                </LabelsWrapper>
                            ),
                            noWrapText: true,
                            title: 'Learner Engagement',
                        }}
                        padding={'40px'}
                        testId="pUhMh-M"
                    />
                </div>
            </ContentWrapper>
        </PlaceholderLayout>
    );
};
