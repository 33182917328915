import { nanoid } from 'nanoid';

import { WorldMapChartProps } from '@speeki/charts';
import { countriesIterable, relationshipsIterable } from '@speeki/dictionary';
import {
    DateRangeItem,
    SelectionFilterProps,
} from '@speeki/global-ui-components';

// FIXME: compatibility with @speeki/global-ui-components
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type Filters = {
    countries: string[];
    relationships: string[];
    statuses: string[];
};

const parseData = (data: readonly { key: string; value: string }[]) =>
    data
        .filter(({ key }) => key !== 'ALL')
        .map(({ key, value }) => ({
            key,
            label: value,
        }));

export const selectionFilerMock: SelectionFilterProps<{
    countries: string[];
    relationships: string[];
    statuses: string[];
}>['fields'] = [
    {
        label: 'Status',
        name: 'statuses',
        options: [
            { key: 'ACTIVE', label: 'Active' },
            { key: 'INACTIVE', label: 'Inactive' },
        ],
    },
    {
        label: 'Relationship with company',
        name: 'relationships',
        options: parseData(relationshipsIterable),
        type: 'radio',
    },
    {
        label: 'Country',
        name: 'countries',
        options: parseData(countriesIterable),
    },
];

export enum DateRangeValue {
    ALL = 'ALL',
    LAST_24_HOURS = 'LAST_24_HOURS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_7_DAYS = 'LAST_7_DAYS',
}

export const dateList: DateRangeItem<DateRangeValue>[] = [
    {
        key: DateRangeValue.LAST_24_HOURS,
        label: 'Last 24 hours',
    },
    {
        key: DateRangeValue.LAST_7_DAYS,
        label: 'Last 7 days',
    },
    {
        key: DateRangeValue.LAST_30_DAYS,
        label: 'Last 30 days',
    },
    {
        key: DateRangeValue.ALL,
        label: 'All time',
    },
];

export const tilesStatistics = [
    {
        id: nanoid(),
        label: 'amount of emails sent',
        title: 'Email Sent',
        value: '234',
    },
    {
        id: nanoid(),
        label: '54 user opened your email',
        title: 'Email Opened',
        value: '23.07%',
    },
    {
        id: nanoid(),
        label: '50 users clicked links after opening the email',
        title: 'Clicked Rate',
        value: '92.59%',
    },
    {
        id: nanoid(),
        label: 'find your email is useful',
        title: 'Users Feedback',
        value: '100%',
    },
];

export const mockWorldMapChart: {
    data: WorldMapChartProps['data'];
    dataClasses: WorldMapChartProps['dataClasses'];
} = {
    data: [
        { 'iso-a2': 'AT', value: 12 },
        { 'iso-a2': 'AU', value: 26 },
        { 'iso-a2': 'BG', value: 41 },
        { 'iso-a2': 'BY', value: 58 },
        { 'iso-a2': 'CA', value: 14 },
        { 'iso-a2': 'CN', value: 12 },
        { 'iso-a2': 'CZ', value: 32 },
        { 'iso-a2': 'DE', value: 27 },
        { 'iso-a2': 'ES', value: 24 },
        { 'iso-a2': 'HU', value: 12 },
        { 'iso-a2': 'KZ', value: 55 },
        { 'iso-a2': 'MD', value: 57 },
        { 'iso-a2': 'PL', value: 42 },
        { 'iso-a2': 'RO', value: 21 },
        { 'iso-a2': 'SK', value: 52 },
        { 'iso-a2': 'TR', value: 42 },
        { 'iso-a2': 'UA', value: 32 },
        { 'iso-a2': 'US', value: 30 },
    ],
    dataClasses: [
        {
            color: 'rgba(13, 48, 102, 1)',
            from: 51,
            hoverColor: 'rgba(63, 90, 131, 1)',
            name: 'largest size',
        },
        {
            color: 'rgba(20, 71, 153, 1)',
            from: 41,
            hoverColor: 'rgba(68, 108, 172, 1)',
            name: 'large size',
            to: 50,
        },
        {
            color: 'rgba(33, 119, 255, 1)',
            from: 31,
            hoverColor: 'rgba(100, 151, 233, 1)',
            name: 'mid size',
            to: 40,
        },
        {
            color: 'rgba(122, 173, 255, 1)',
            from: 21,
            hoverColor: 'rgba(162, 192, 242, 1)',
            name: 'small size',
            to: 30,
        },
        {
            color: 'rgba(211, 228, 255, 1)',
            from: 0,
            hoverColor: 'rgba(224, 234, 251, 1)',
            name: 'smallest size',
            to: 20,
        },
        {
            color: 'rgba(193, 199, 210, 1)',
            name: 'no data',
            to: 0,
        },
    ],
};
