import { ReactNode } from 'react';

import { Typography } from '@speeki/global-ui-components';

import { LegendItemWrapper, LegendOrnament } from './LegendItem.styled';

export interface LegendItemProps {
    children: ReactNode;
    fill: string;
}

export const LegendItem = ({ children, fill }: LegendItemProps) => {
    return (
        <LegendItemWrapper>
            <LegendOrnament fill={fill} />
            <Typography.TextBody_12_20>{children}</Typography.TextBody_12_20>
        </LegendItemWrapper>
    );
};
