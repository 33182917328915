import styled, { css } from 'styled-components';

export const CardImageWrapper = styled.div`
    height: 80px;
    margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LinkWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    flex-grow: 1;
    margin-top: 26px;
`;

export const Divider = styled.div(
    ({ theme: { palette } }) => css`
        height: 1px;
        background-color: ${palette.neutral.dividers};
        width: 100%;
    `,
);
