import { nanoid } from 'nanoid';
import { Column } from 'react-table';

import { Typography } from '@speeki/global-ui-components';

import { TableBlueText } from '@pages/StaticPlaceholder.styled';

import {
    DescriptionWrapper,
    TextContentWrapper,
    TextWrapper,
} from './CertificatesPlaceholder.styled';

export interface CertificatesPlaceholderTableRow {
    description: {
        standard: string;
        text: string;
    };
    id: string;
    topic: string;
}

export const certificatesPlaceholderHeaderData: Column<CertificatesPlaceholderTableRow>[] =
    [
        {
            Cell: ({ value }) => <TableBlueText>{value}</TableBlueText>,
            Header: 'Topic',
            accessor: 'topic',
            width: 280,
        },
        {
            Cell: ({ value }) => {
                return (
                    <DescriptionWrapper>
                        <Typography.TextBody_14_24>
                            {value.standard}
                        </Typography.TextBody_14_24>
                        <TextContentWrapper>
                            <TextWrapper>{value.text}</TextWrapper>
                            <TableBlueText>See more</TableBlueText>
                        </TextContentWrapper>
                    </DescriptionWrapper>
                );
            },
            Header: 'Description',
            accessor: 'description',
            disableSortBy: true,
            width: 672,
        },
    ];

export const certificatesPlaceholderTableData: CertificatesPlaceholderTableRow[] =
    [
        {
            id: nanoid(),
            description: {
                standard:
                    'ISO 37001 Anti-Bribery Management Systems Certification:',
                text: 'Anti-Bribery & Corruption...',
            },
            topic: 'Anti-Bribery & Corruption Certification',
        },
        {
            id: nanoid(),
            description: {
                standard: 'ISO 37301 Compliance Certification:',
                text: 'Compliance Framework...',
            },
            topic: 'Compliance Framework Certification',
        },
        {
            id: nanoid(),
            description: {
                standard: 'ISO 37000 Governance Guidelines:',
                text: 'Governance Guidelines...',
            },
            topic: 'Governance Guidelines Audit',
        },
        {
            id: nanoid(),
            description: {
                standard: 'ISO 37002 Whistleblowing Guidelines:',
                text: 'Whistleblowing Guidelines...',
            },
            topic: 'Whistleblowing Programme Audit',
        },
    ];
