import { useState } from 'react';

import {
    dateList,
    DateRangeValue,
    Filters,
    mockWorldMapChart,
    selectionFilerMock,
    tilesStatistics,
} from './CommsPlaceholder.mocks';

import { MapWithTable } from '@speeki/charts';
import {
    Button,
    ChartCard,
    DateRange,
    DateRangeType,
    SelectionFilter,
    Tabs,
    Typography,
} from '@speeki/global-ui-components';

import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    ContentHeader,
    ContentHeaderText,
    ContentWrapper,
    HeaderActions,
} from '@pages/StaticPlaceholder.styled';
import { PlaceholderSection } from '@pages/StaticPlaceholder.styled';

import {
    ChartWrapper,
    TabsWrapper,
    Tile,
    TileLabel,
    TilesWrapper,
    TileTitle,
    TileValue,
} from './CommsPlaceholder.styled';

export const CommsPlaceholder = () => {
    const [value, setValue] = useState<DateRangeType<DateRangeValue>>(
        DateRangeValue.LAST_30_DAYS,
    );

    const [filters, setFilters] = useState<Filters>({
        countries: [],
        relationships: [],
        statuses: [],
    });

    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgComments',
                title: 'Communications',
            }}
        >
            <ContentWrapper gap={'32px'}>
                <ContentHeader>
                    <ContentHeaderText>
                        View and manage your communications with your key
                        stakeholders.
                    </ContentHeaderText>
                    <HeaderActions>
                        <Button testId="G-r29Ky" variant="secondary">
                            Create a template
                        </Button>
                        <Button testId="ydyHPIZ" variant="primary">
                            Start a campaign
                        </Button>
                    </HeaderActions>
                </ContentHeader>

                <TabsWrapper>
                    <Tabs
                        data={[
                            {
                                active: true,
                                name: 'Statistics',
                                onClick: () => {},
                                testId: 'q2ufplY',
                            },
                            {
                                active: false,
                                name: 'Campaign Builder',
                                onClick: () => {},
                                testId: 'c1aylcQ',
                            },
                        ]}
                        testId="OfwPo5e"
                    />

                    <PlaceholderSection gap={'32px'}>
                        <ContentHeader>
                            <Typography.TextH3_24_34>
                                Statistics summary
                            </Typography.TextH3_24_34>
                            <HeaderActions>
                                <DateRange
                                    defaultValue={DateRangeValue.LAST_30_DAYS}
                                    fields={dateList}
                                    onChange={setValue}
                                    testId="LoDGT1v"
                                    value={value}
                                />

                                <SelectionFilter
                                    align="right"
                                    fields={selectionFilerMock}
                                    filters={filters}
                                    setFilters={setFilters}
                                    testId="RU7FfCo"
                                />
                            </HeaderActions>
                        </ContentHeader>

                        <TilesWrapper columns={tilesStatistics.length}>
                            {tilesStatistics.map(
                                ({ label, title, value }, index) => (
                                    <Tile key={index}>
                                        <TileTitle fontWeight="bold">
                                            {title}
                                        </TileTitle>
                                        <TileValue>{value ?? 0}</TileValue>
                                        <TileLabel>{label}</TileLabel>
                                    </Tile>
                                ),
                            )}
                        </TilesWrapper>

                        <ChartWrapper>
                            <ChartCard
                                chart={
                                    <MapWithTable
                                        data={mockWorldMapChart.data}
                                        dataClasses={
                                            mockWorldMapChart.dataClasses
                                        }
                                        itemNumber={4}
                                        testId="d4Rellw"
                                    />
                                }
                                gap="40px"
                                header={{
                                    description: {
                                        text: '2021/01/01 - 2021/01/31',
                                    },
                                    noWrapText: true,
                                    title: 'Email opened by location',
                                }}
                                padding={'40px 40px 70px'}
                                testId="pUhMh-M"
                            />
                        </ChartWrapper>
                    </PlaceholderSection>
                </TabsWrapper>
            </ContentWrapper>
        </PlaceholderLayout>
    );
};
