import { css } from 'styled-components';

import GTWProMediumEotBold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.eot';
import GTWProMediumTtfBold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.ttf';
import GTWProMediumWoffBold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.woff';
import GTWProMediumWoff2Bold from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Bold.woff2';
import GTWProMediumEotRegular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.eot';
import GTWProMediumTtfRegular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.ttf';
import GTWProMediumWoffRegular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.woff';
import GTWProMediumWoff2Regular from '@assets/fonts/GTWalsheimPro/GTWalsheimPro-Regular.woff2';

export const globalFonts = css`
    @font-face {
        font-family: 'GTWalsheimpro';
        font-weight: 400;
        src: url(${GTWProMediumEotRegular});
        src: url('${GTWProMediumEotRegular}#iefix') format('embedded-opentype'),
            url(${GTWProMediumWoff2Regular}) format('woff2'),
            url(${GTWProMediumWoffRegular}) format('woff'),
            url(${GTWProMediumTtfRegular}) format('truetype');
    }

    @font-face {
        font-family: 'GTWalsheimpro';
        font-weight: 700;
        src: url(${GTWProMediumEotBold});
        src: url('${GTWProMediumEotBold}#iefix') format('embedded-opentype'),
            url(${GTWProMediumWoff2Bold}) format('woff2'),
            url(${GTWProMediumWoffBold}) format('woff'),
            url(${GTWProMediumTtfBold}) format('truetype');
    }
`;
