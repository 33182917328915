import React, { ButtonHTMLAttributes, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { IconManager, IconManagerProps, useIconState } from '@speeki/icons';
import { useHover } from '@speeki/react-hooks';

import { DefaultTextButton } from './ActionButton.styled';

export interface ActionButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: string;
    iconProps?: IconManagerProps;
    /**
     * Leave it empty and generate the id in the project with @speeki/frontend-utils helper.
     */
    testId: string;
}

export const ActionButton = React.forwardRef<
    HTMLButtonElement,
    ActionButtonProps
>(({ children, iconProps, testId, ...rest }: ActionButtonProps, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const hover = useHover(buttonRef);
    const { iconState } = useIconState(hover.value);

    return (
        <DefaultTextButton
            {...rest}
            data-testid={testId}
            ref={mergeRefs([ref, buttonRef])}
        >
            {iconProps && <IconManager {...iconProps} iconState={iconState} />}
            {children}
        </DefaultTextButton>
    );
});
