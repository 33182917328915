import { AnchorHTMLAttributes, ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';

import { CardLink } from '@components/atoms/CardLink/CardLink';
import { PlaceholderCard } from '@components/atoms/PlaceholderCard/PlaceholderCard';

import {
    ContentWrapper,
    HTMLWrapper,
    Label,
    LabelCardHeader,
    LabelsWrapper,
    LinkWrapper,
    PlaceholderCardTitle,
} from './LabelCard.styled';

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    text: ReactNode;
}

export interface LabelCardProps {
    isFeed?: boolean;
    labels?: {
        background: string;
        color: string;
        label: string;
    }[];
    linkProps: LinkProps;
    text: string;
    title: ReactNode;
}

export const LabelCard = ({
    isFeed = false,
    labels,
    linkProps,
    text,
    title,
}: LabelCardProps) => {
    return (
        <PlaceholderCard>
            <LabelCardHeader>
                <LabelsWrapper>
                    {labels?.map(({ background, color, label }) => (
                        <Label
                            background={background}
                            color={color}
                            key={label}
                        >
                            {label}
                        </Label>
                    ))}
                </LabelsWrapper>
            </LabelCardHeader>
            <ContentWrapper>
                <PlaceholderCardTitle>{title}</PlaceholderCardTitle>

                {isFeed ? (
                    <HTMLWrapper
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(text, {
                                allowedTags:
                                    sanitizeHtml.defaults.allowedTags.concat([
                                        'img',
                                    ]),
                            }),
                        }}
                    />
                ) : (
                    <PlaceholderCard.Description>
                        {text}
                    </PlaceholderCard.Description>
                )}
            </ContentWrapper>
            <LinkWrapper>
                <CardLink {...linkProps}>{linkProps.text}</CardLink>
            </LinkWrapper>
        </PlaceholderCard>
    );
};
