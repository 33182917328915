import styled, { css } from 'styled-components';

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const TextContentWrapper = styled.div`
    display: flex;
    gap: 2px;
    margin-left: 20px;
    position: relative;
`;

export const TextWrapper = styled.div(
    ({ theme: { palette, radius } }) => css`
        &::before {
            background-color: ${palette.text.primary};
            border-radius: ${radius['4']}px;
            content: '';
            height: 4px;
            left: -12px;
            position: absolute;
            top: 10px;
            width: 4px;
        }
    `,
);
