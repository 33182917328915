import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as ReactDOMServer from 'react-dom/server';
import { useTheme } from 'styled-components';

import { WebThemeProvider } from '@speeki/theme';

import { LegendItem } from '@components/atoms/LegendItem/LegendItem';

import {
    TooltipText,
    TooltipTitle,
    TooltipWrapper,
} from './LearnerEngagementChart.styled';

export interface LearnerEngagementChartProps {}

export const LearnerEngagementChart = ({}: LearnerEngagementChartProps) => {
    const { palette } = useTheme();

    const options: Options = {
        chart: {
            className: 'hc-areaspline-chart',
            height: 440,
            type: 'areaspline',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                color: '#C1C7D2',
                data: [60, 64, 72, 77, 76, 72, 68, 66, 65, 65, 64],
                enableMouseTracking: true,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, 'rgba(193, 199, 210, 0.46)'],
                        [1, 'rgba(245, 247, 255, 0)'],
                    ],
                },
                name: 'Assigned Learners',
                pointPlacement: 'on',
                type: 'areaspline',
            },
            {
                color: palette.chart.blue500,
                data: [40, 39, 41, 48, 55, 78, 69, 50, 40, 62, 50],
                enableMouseTracking: true,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, '#D3E4FF'],
                        [1, 'rgba(245, 247, 255, 0)'],
                    ],
                },
                name: 'Active Learners',
                pointPlacement: 'on',
                type: 'areaspline',
            },
        ],
        title: undefined,
        tooltip: {
            backgroundColor: palette.text.secondary,
            borderRadius: 6,
            borderWidth: 0,
            formatter: function () {
                return ReactDOMServer.renderToStaticMarkup(
                    <WebThemeProvider variant={'default'}>
                        <TooltipWrapper>
                            <TooltipTitle>{this.x}</TooltipTitle>
                            {this.points?.reverse().map(({ point, series }) => (
                                <div>
                                    <LegendItem fill={series.color as string}>
                                        <TooltipText>
                                            {series.name}: {point.y}
                                        </TooltipText>
                                    </LegendItem>
                                </div>
                            ))}
                        </TooltipWrapper>
                    </WebThemeProvider>,
                );
            },
            outside: false,
            padding: 0,
            shadow: false,
            shared: true,
            split: false,
            useHTML: true,
        },
        xAxis: {
            categories: [
                'Dec 31 - Jan 6',
                'Jan 7 - Jan 13',
                'Jan 14 - Jan 20',
                'Jan 21 - Jan 27',
                'Jan 28 - Feb 3',
                'Feb 4 - Feb 10',
                'Feb 11 - Feb 17',
                'Feb 18 - Feb 24',
                'Feb 25 - Mar 3',
                'Mar 4 - Mar 10',
                'Mar 11 - Mar 17',
            ],
            labels: {
                formatter: function () {
                    return '<b>' + this.value + '</b>';
                },
                rotation: 315,
            },
            lineColor: '#8691A7',
            lineWidth: 1,
            tickmarkPlacement: 'on',
            type: 'category',
        },
        yAxis: {
            gridLineColor: 'rgba(223, 225, 231, 0.5)',
            labels: {
                formatter: function () {
                    return '<b>' + this.value + '</b>';
                },
            },
            lineColor: '#8691A7',
            lineWidth: 1,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            title: {
                text: 'Amount of learners',
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};
