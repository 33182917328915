import { usePermissionsCheck, useUserSession } from '@speeki/client-admin';

export const useUserModules = () => {
    const { data: userData } = useUserSession();
    const { permissionsCheck } = usePermissionsCheck();

    const hasIncidentsAccess =
        !!userData?.user.tenant?.modules.cases &&
        permissionsCheck(['DASHBOARD']);
    const hasEsgAccess =
        !!userData?.user.tenant?.modules.esg &&
        permissionsCheck(['ESG_DASHBOARD', 'ESG_QUESTIONNAIRE'], true);
    const hasSurveyAccess =
        !!userData?.user.tenant?.modules.surveys &&
        permissionsCheck(['SURVEYS_DASHBOARD']);
    const hasDisclosureAccess =
        !!userData?.user.tenant?.modules.disclosures &&
        permissionsCheck(['DISCLOSURES_DASHBOARD']);

    const hasReportingAccess = permissionsCheck(['ESG_REPORTING']);

    return {
        hasDisclosureAccess,
        hasEsgAccess,
        hasIncidentsAccess,
        hasNoAccess:
            !hasIncidentsAccess &&
            !hasEsgAccess &&
            !hasSurveyAccess &&
            !hasDisclosureAccess &&
            !hasReportingAccess,
        hasReportingAccess,
        hasSurveyAccess,
    };
};
