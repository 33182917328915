import { ProgressIndicator } from '@speeki/client-admin';
import { Typography } from '@speeki/global-ui-components';

import { CardLink } from '@components/atoms/CardLink/CardLink';

import {
    LinkWrapper,
    QuestionnaireStatisticContent,
    QuestionnaireStatisticHeader,
    QuestionnaireStatisticInfo,
    QuestionnaireStatisticWrapper,
} from './QuestionnaireStatistic.styled';

export interface QuestionnaireStatisticProps {
    link: string;
    loading: boolean;
    progressIndicator: {
        backgroundColor: string;
        progressColor: string;
    };
    statistics: {
        allQuestions: number;
        filledQuestions: number;
    };
    title: string;
}

export const QuestionnaireStatistic = ({
    link,
    progressIndicator,
    statistics,
    title,
}: QuestionnaireStatisticProps) => {
    const percentFilled = Math.floor(
        (statistics.filledQuestions / statistics.allQuestions) * 100,
    );

    return (
        <QuestionnaireStatisticWrapper>
            <QuestionnaireStatisticHeader>
                <Typography.TextBody_14_24>{title}</Typography.TextBody_14_24>
            </QuestionnaireStatisticHeader>
            <QuestionnaireStatisticContent>
                <ProgressIndicator
                    backgroundColor={progressIndicator.backgroundColor}
                    progressColor={progressIndicator.progressColor}
                    progressValue={percentFilled}
                />
                {statistics.filledQuestions === 0 ? (
                    <LinkWrapper>
                        <CardLink href={link}>Start</CardLink>
                    </LinkWrapper>
                ) : (
                    <QuestionnaireStatisticInfo>
                        {statistics.filledQuestions}/{statistics.allQuestions} (
                        {parseFloat(percentFilled.toString()).toFixed()}%)
                    </QuestionnaireStatisticInfo>
                )}
            </QuestionnaireStatisticContent>
        </QuestionnaireStatisticWrapper>
    );
};
