import { ReactNode } from 'react';

import {
    DefaultLayout,
    DefaultLayoutProps,
    useLayout,
} from '@speeki/client-admin';

import { authService } from '@services/AuthService/AuthService';

export interface PlaceholderLayoutProps extends Partial<DefaultLayoutProps> {
    children: ReactNode;
}

export const PlaceholderLayout = ({
    children,
    ...layoutProps
}: PlaceholderLayoutProps) => {
    const props = useLayout({
        onLogout: () => authService.logout(),
    });

    return (
        <DefaultLayout {...props} {...layoutProps}>
            {children}
        </DefaultLayout>
    );
};
