import {
    documentsPlaceholderHeaderData,
    documentsPlaceholderTableData,
    DocumentsPlaceholderTableRow,
} from './DocumentsPlaceholder.settings';

import { Button, DefaultTable } from '@speeki/global-ui-components';

import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    ContentHeader,
    ContentHeaderText,
    ContentWrapper,
} from '@pages/StaticPlaceholder.styled';

export const DocumentsPlaceholder = () => {
    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgDocuments',
                title: 'Documents',
            }}
        >
            <ContentWrapper gap={'48px'}>
                <ContentHeader>
                    <ContentHeaderText>
                        A centralized hub to retrieve all of your supporting
                        files.
                    </ContentHeaderText>
                    <Button testId="Jf4wwca" variant="primary">
                        Upload new file
                    </Button>
                </ContentHeader>
                <div>
                    <DefaultTable<DocumentsPlaceholderTableRow>
                        columns={documentsPlaceholderHeaderData}
                        data={documentsPlaceholderTableData}
                        pagination={{
                            options: {
                                defaultPageSize: 10,
                                totalCount:
                                    documentsPlaceholderTableData.length,
                            },
                        }}
                        tableRow={{
                            // eslint-disable-next-line no-console
                            onRowClick: () => console.log('onClick'),
                            testId: '79OomG7',
                        }}
                        tableState={{
                            hiddenColumns: ['id'],
                        }}
                        testId="wRhSoGu"
                    />
                </div>
            </ContentWrapper>
        </PlaceholderLayout>
    );
};
