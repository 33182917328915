import styled, { css } from 'styled-components';

export const CardLabelWrapper = styled.div(
    ({ theme: { palette, radius } }) => css`
        align-items: center;
        background-color: ${palette.neutral.gray};
        border-radius: ${radius[4]}px;
        display: flex;
        gap: 8px;
        padding: 10px 24px;
    `,
);
