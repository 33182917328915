import { useNavigation } from '@speeki/client-admin';
import { Typography } from '@speeki/global-ui-components';

import { PlaceholderCard } from '@components/atoms/PlaceholderCard/PlaceholderCard';
import { ActionButton } from '@components/molecules/ActionButton/ActionButton';

import { useUserModules } from '@utils/hooks/useUserModules';

import { ActionsWrapper, HeaderWrapper } from './QuickActions.styled';

export const QuickActions = () => {
    const { navigate } = useNavigation();

    const {
        hasDisclosureAccess,
        hasEsgAccess,
        hasIncidentsAccess,
        hasNoAccess,
        hasReportingAccess,
        hasSurveyAccess,
    } = useUserModules();

    if (hasNoAccess) return null;

    return (
        <PlaceholderCard>
            <HeaderWrapper>
                <Typography.TextH3_24_34>Quick actions</Typography.TextH3_24_34>
            </HeaderWrapper>
            <ActionsWrapper>
                {hasEsgAccess && (
                    <ActionButton
                        iconProps={{
                            icon: 'Svg2DChartPie24',
                            size: 24,
                        }}
                        onClick={() =>
                            navigate({
                                path: '/esg',
                            })
                        }
                        testId="Q5ENZVg"
                    >
                        Manage ESG Programmes
                    </ActionButton>
                )}
                {hasReportingAccess && (
                    <ActionButton
                        iconProps={{
                            icon: 'SvgPulse24',
                            size: 24,
                        }}
                        onClick={() =>
                            navigate({
                                path: '/reporting',
                            })
                        }
                        testId="GkiUzgP"
                    >
                        Generate ESG Report
                    </ActionButton>
                )}
                {hasIncidentsAccess && (
                    <ActionButton
                        iconProps={{
                            icon: 'Svg2DMessaging24',
                            size: 24,
                        }}
                        onClick={() =>
                            navigate({
                                path: '/incidents/dashboard',
                            })
                        }
                        testId="XbfSXDT"
                    >
                        Manage Incidents
                    </ActionButton>
                )}
                {hasSurveyAccess && (
                    <ActionButton
                        iconProps={{
                            icon: 'Svg2DSurvey24',
                            size: 24,
                        }}
                        onClick={() =>
                            navigate({
                                path: '/survey/templates',
                            })
                        }
                        testId="EQSf4Ey"
                    >
                        Send Survey
                    </ActionButton>
                )}
                {hasDisclosureAccess && (
                    <ActionButton
                        iconProps={{
                            icon: 'Svg2DDAndD24',
                            size: 24,
                        }}
                        onClick={() =>
                            navigate({
                                path: '/disclosures/dashboard',
                            })
                        }
                        testId="jGJQS2k"
                    >
                        Review Disclosure
                    </ActionButton>
                )}
            </ActionsWrapper>
        </PlaceholderCard>
    );
};
