import { CardLink } from '@components/atoms/CardLink/CardLink';
import { PlaceholderCard } from '@components/atoms/PlaceholderCard/PlaceholderCard';

import {
    CardImageWrapper,
    ContentWrapper,
    Divider,
    LinkWrapper,
} from './DetailsCard.styled';

export interface PlaceholderCardProps {
    description?: string;
    expired?: boolean;
    expiryDate: string;
    image: string;
    scopeDescription?: string;
    title: string;
    viewDetailsLink: string;
}

export const DetailsCard = ({
    description,
    expired = false,
    expiryDate,
    image,
    scopeDescription,
    title,
    viewDetailsLink,
}: PlaceholderCardProps) => {
    return (
        <PlaceholderCard>
            <CardImageWrapper>
                <img alt="" src={image} />
            </CardImageWrapper>
            <ContentWrapper>
                <PlaceholderCard.Title>{title}</PlaceholderCard.Title>
                {description && (
                    <PlaceholderCard.Description>
                        {description}
                    </PlaceholderCard.Description>
                )}
                <Divider />
                {scopeDescription ? (
                    <div>
                        <PlaceholderCard.Description>
                            Expiry:{' '}
                            <PlaceholderCard.BoldText>
                                {expiryDate}{' '}
                            </PlaceholderCard.BoldText>
                            {expired && (
                                <PlaceholderCard.RedText>
                                    (expired)
                                </PlaceholderCard.RedText>
                            )}
                        </PlaceholderCard.Description>
                        <PlaceholderCard.Description>
                            Scope:{' '}
                            <PlaceholderCard.BoldText>
                                Anti-bribery management system for Headquarters
                                operations
                            </PlaceholderCard.BoldText>
                        </PlaceholderCard.Description>
                    </div>
                ) : (
                    <PlaceholderCard.Description>
                        Expiry:{' '}
                        <PlaceholderCard.BoldText>
                            {expiryDate}{' '}
                        </PlaceholderCard.BoldText>
                        {expired && (
                            <PlaceholderCard.RedText>
                                (expired)
                            </PlaceholderCard.RedText>
                        )}
                    </PlaceholderCard.Description>
                )}
            </ContentWrapper>
            <LinkWrapper>
                <CardLink href={viewDetailsLink} target={'_blank'}>
                    View details
                </CardLink>
            </LinkWrapper>
        </PlaceholderCard>
    );
};
