import { Outlet, ScrollRestoration } from 'react-router-dom';

import { AppModals } from './AppModals';

import {
    BackgroundOverlay,
    isModalScrollBlockedVar,
    OnboardingSlider,
    useInitializeGraphStorage,
    useOnboarding,
} from '@speeki/client-admin';
import { ToastNotification } from '@speeki/global-ui-components';

import { GlobalDynamicStyled } from '@components/ions/global.styled';

import { useReactiveVar } from '@apollo/client';
import { adminTips, userTips } from '@shared/tipsSlider';

export const App = () => {
    useInitializeGraphStorage();
    const { adminOnboarding } = useOnboarding();

    const isBodyScrollBlocked = useReactiveVar(isModalScrollBlockedVar);

    return (
        <div>
            <GlobalDynamicStyled
                blockScroll={isBodyScrollBlocked}
                showAdminOnboarding={adminOnboarding}
            />
            <ScrollRestoration />
            <Outlet />
            <ToastNotification />

            <AppModals />
            <OnboardingSlider adminTips={adminTips} userTips={userTips} />
            <BackgroundOverlay />
        </div>
    );
};
