import {
    LabelCard,
    LabelCardProps,
} from '@components/molecules/LabelCard/LabelCard';
import { EsgContentWrapper } from '@components/organisms/EsgArticlesAndFeeds/EsgArticlesAndFeeds.styled';
import { EsgArticlesAndFeedsSkeleton } from '@components/organisms/EsgArticlesAndFeeds/EsgArticlesAndFeedsSkeleton';

interface EsgArticlesProps {
    data: LabelCardProps[];
}

export const EsgArticles = ({ data }: EsgArticlesProps) => {
    if (!data.length) return <EsgArticlesAndFeedsSkeleton />;

    return (
        <EsgContentWrapper>
            {data.map((props, index) => (
                <LabelCard key={index} {...props} />
            ))}
        </EsgContentWrapper>
    );
};
