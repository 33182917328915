import styled, { css } from 'styled-components';

import { PlaceholderCard } from '@components/atoms/PlaceholderCard/PlaceholderCard';

export const LabelCardHeader = styled.div`
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-bottom: 12px;
`;

export const LabelsWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const Label = styled.div<{ background: string; color: string }>(
    ({ background, color, theme: { fontSize, fontWeight, radius } }) => css`
        background: ${background};
        border-radius: ${radius[8]}px;
        color: ${color};
        font-size: ${fontSize[14]}px;
        font-weight: ${fontWeight.bold};
        line-height: 20px;
        padding: 2px 16px;
        text-transform: uppercase;
    `,
);

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LinkWrapper = styled.div`
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    margin-top: 26px;
`;

export const ReleaseNotesDescription = styled.div(
    ({ theme: { font, fontSize } }) => css`
        font-family: ${font.primary};
        font-size: ${fontSize[14]}px;
        letter-spacing: -0.015em;
        line-height: 1.43;
        margin: 0;

        p {
            margin: 0;
        }
    `,
);

export const HTMLWrapper = styled.div(
    ({ theme: { radius } }) => css`
        align-items: center;
        display: flex;
        flex-direction: column;

        p {
            overflow: hidden;
            width: 100%;

            img {
                border-radius: ${radius[4]}px;
                height: 300px;
                object-fit: cover;
                width: 100%;
            }
        }
    `,
);

export const PlaceholderCardTitle = styled(PlaceholderCard.Title)`
    display: block;
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;

    //NOTE: needed to multiline text-overflow: ellipsis
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
