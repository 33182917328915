import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
`;

export const TilesWrapper = styled.div<{ columns: number }>(
    ({ columns }) => css`
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(${columns}, 1fr);
    `,
);

export const Tile = styled.div(
    ({ theme: { palette, radius } }) => css`
        background: ${palette.tertiary.background};
        border-radius: ${radius[4]}px;
        padding: 24px 32px;
    `,
);

export const TileValue = styled(Typography.TextH2_28_44)(
    ({ theme: { font, fontSize } }) => css`
        font-family: ${font.primary};
        font-size: ${fontSize[56]}px;
        line-height: 74px;
        text-align: center;
        word-break: break-all;
    `,
);

export const TileTitle = styled(Typography.TextBody_14_20)`
    margin-bottom: 16px;
`;

export const TileLabel = styled(Typography.TextBody_14_24)`
    text-align: center;
`;

export const ChartWrapper = styled.div`
    height: 776px;
`;
