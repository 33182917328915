import abSystemsImage from '@assets/images/AB-systems.jpg';

import { DragAndDropInput } from '@components/atoms/DragAndDropInput/DragAndDropInput';
import { DetailsCard } from '@components/molecules/DetailsCard/DetailsCard';

import { ObtainedCertificationsWrapper } from './ObtainedCertifications.styled';

export const ObtainedCertifications = () => {
    return (
        <ObtainedCertificationsWrapper>
            <DetailsCard
                expiryDate="12-12-2024"
                image={abSystemsImage}
                scopeDescription={
                    'Anti-bribery management system for Headquarters operations'
                }
                title="ISO 37001 - Anti-Bribery Management System"
                viewDetailsLink="https://ratings.speeki.com"
            />

            <DetailsCard
                expiryDate="01-04-2024"
                image={abSystemsImage}
                scopeDescription={
                    'The Group has established and maintain according to the best practices of Whistleblowing Guideline'
                }
                title="ISO 37002 - Whistleblowing management systems - Guidelines"
                viewDetailsLink="https://ratings.speeki.com"
            />

            <DragAndDropInput text="obtained report or certification" />
        </ObtainedCertificationsWrapper>
    );
};
