import { WelcomeModal, WhatsNewModal } from '@speeki/client-admin';

/**
 * Add to this component only global modals.
 */
export const AppModals = () => {
    return (
        <>
            <WelcomeModal />
            <WhatsNewModal />
        </>
    );
};
