import '@speeki/frontend-sentry/instrument';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { AppInitializer } from './App/AppInitializer.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <AppInitializer />
    </React.StrictMode>,
);
