import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useUserSession } from '@speeki/client-admin';

import { LabelCardProps } from '@components/molecules/LabelCard/LabelCard';

import { WebflowDocument } from '@graphql/generated/graphql';

import { useUserModules } from '@utils/hooks/useUserModules.tsx';

import { useQuery } from '@apollo/client';

const blueBackgroundLabelIndex = [0, 2, 3, 5];

const isLabelBackgroundBlue = (index: number): boolean => {
    if (blueBackgroundLabelIndex.includes(index)) return true;

    return false;
};

export const useDashboardPlaceholder = () => {
    const { palette } = useTheme();

    const { hasEsgAccess, hasIncidentsAccess, hasSurveyAccess } =
        useUserModules();

    const { data: userData } = useUserSession();

    const { data } = useQuery(WebflowDocument);

    const articlesData: LabelCardProps[] = useMemo(
        () =>
            data?.webflow?.esgArticles?.nodes?.map((article, index) => ({
                labels: [
                    {
                        background: isLabelBackgroundBlue(index)
                            ? palette.chart.blue25
                            : palette.primary.background,

                        color: isLabelBackgroundBlue(index)
                            ? palette.chart.blue500
                            : palette.semantic.success,
                        label: article.category ?? '',
                    },
                ],
                linkProps: {
                    href: article.url ?? '',
                    target: '_blank',
                    text: 'Read full article',
                },
                text: article.description ?? '',
                title: article.title ?? '',
            })) ?? [],
        [data],
    );

    const feedsData: LabelCardProps[] = useMemo(
        () =>
            data?.webflow?.feed?.nodes?.map((feed) => ({
                isFeed: true,
                linkProps: {
                    href: feed.url ?? '',
                    target: '_blank',
                    text: 'Read full article',
                },
                text: feed.description ?? '',
                title: feed.title ?? '',
            })) ?? [],
        [data],
    );

    const latestReleaseData = data?.webflow?.latestRelease ?? {};
    const supportArticleData = data?.webflow?.supportArticle ?? {};

    const latestNewsData: LabelCardProps[] = useMemo(
        () =>
            [
                ...(!!Object.keys(latestReleaseData).length
                    ? [
                          {
                              labels: [
                                  {
                                      background: palette.primary.background,
                                      color: palette.semantic.success,
                                      label: latestReleaseData.category ?? '',
                                  },
                              ],
                              linkProps: {
                                  href: latestReleaseData.url ?? '',
                                  target: '_blank',
                                  text: 'Read full release',
                              },
                              text: latestReleaseData.description ?? '',
                              title: latestReleaseData.title ?? '',
                          },
                      ]
                    : []),
                ...(!!Object.keys(supportArticleData).length
                    ? [
                          {
                              labels: [
                                  {
                                      background: palette.tertiary.background,
                                      color: palette.esg.esrs,
                                      label: supportArticleData.category ?? '',
                                  },
                              ],
                              linkProps: {
                                  href: supportArticleData.url ?? '',
                                  target: '_blank',
                                  text: 'Go to support center',
                              },
                              text: supportArticleData.description ?? '',
                              title: supportArticleData.title ?? '',
                          },
                      ]
                    : []),
            ] ?? [],
        [latestReleaseData, supportArticleData],
    );
    return {
        articlesData,
        feedsData,
        hasEsgAccess,
        hasIncidentsAccess,
        hasSurveyAccess,
        latestNewsData,
        userName: userData?.user.firstName,
    };
};
