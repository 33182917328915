import { __DEV__ } from '@speeki/client-admin';

import { cache } from '@graphql/cache';
import { links } from '@graphql/links';

import { ApolloClient } from '@apollo/client';

export const client = new ApolloClient({
    cache,
    connectToDevTools: __DEV__,
    link: links,
    name: 'speeki-demo-static-pages',
});
