import goldImage from '@assets/images/gold.jpg';
import platinumImage from '@assets/images/platinum.jpg';

import { DragAndDropInput } from '@components/atoms/DragAndDropInput/DragAndDropInput';
import { DetailsCard } from '@components/molecules/DetailsCard/DetailsCard';

import { CompletedAssessmentsWrapper } from './CompletedAssessments.styled';

export const CompletedAssessments = () => {
    return (
        <CompletedAssessmentsWrapper>
            <DetailsCard
                description="Demonstrated the ability to govern this subject."
                expiryDate="23-12-2023"
                image={platinumImage}
                title="Anti-bribery and corruption"
                viewDetailsLink="https://ratings.speeki.com"
            />

            <DetailsCard
                description="Passed minimum required standard for governing
                whistleblowing process."
                expired
                expiryDate="23-06-2023"
                image={goldImage}
                title="Whistleblowing"
                viewDetailsLink="https://ratings.speeki.com"
            />

            <DragAndDropInput text="completed assessment" />
        </CompletedAssessmentsWrapper>
    );
};
