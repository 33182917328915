import { createGlobalStyle, css } from 'styled-components';

import { basicStyles } from '@speeki/client-admin';

import { globalFonts } from '@components/ions/fonts';

export const GlobalStyle = createGlobalStyle`
        ${basicStyles}
        ${globalFonts}
    `;

export const GlobalDynamicStyled = createGlobalStyle<{
    blockScroll?: boolean;
    showAdminOnboarding?: boolean;
}>(
    ({ blockScroll, showAdminOnboarding }) => css`
        body {
            ${blockScroll && 'overflow: hidden'};
            ${showAdminOnboarding && 'pointer-events: none'};
        }
    `,
);
