import { useDashboardPlaceholder } from './useDashboardPlaceholder';

import { Typography } from '@speeki/global-ui-components';

import { EsgArticles } from '@components/organisms/EsgArticlesAndFeeds/EsgArticles/EsgArticles';
import { EsgFeeds } from '@components/organisms/EsgArticlesAndFeeds/EsgFeeds/EsgFeeds';
import { LatestNews } from '@components/organisms/LatestNews/LatestNews';
import { QuickActions } from '@components/organisms/QuickActions/QuickActions';
import { Statistics } from '@components/organisms/Statistics/Statistics';
import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import { PlaceholderSection } from '@pages/StaticPlaceholder.styled';

import { DashboardPlaceholderWrapper } from './DashboardPlaceholder.styled';

export const DashboardPlaceholder = () => {
    const {
        articlesData,
        feedsData,
        hasEsgAccess,
        hasIncidentsAccess,
        hasSurveyAccess,
        latestNewsData,
        userName,
    } = useDashboardPlaceholder();

    return (
        <PlaceholderLayout>
            <DashboardPlaceholderWrapper>
                <Typography.TextH1_36_52>
                    Hi {userName}!
                </Typography.TextH1_36_52>

                <QuickActions />

                {(hasEsgAccess || hasIncidentsAccess || hasSurveyAccess) && (
                    <PlaceholderSection>
                        <Typography.TextH3_24_34>
                            Statistics
                        </Typography.TextH3_24_34>
                        <Statistics />
                    </PlaceholderSection>
                )}

                <PlaceholderSection>
                    <Typography.TextH3_24_34>
                        ESG articles
                    </Typography.TextH3_24_34>
                    <EsgArticles data={articlesData} />
                </PlaceholderSection>

                <PlaceholderSection>
                    <Typography.TextH3_24_34>ESG feeds</Typography.TextH3_24_34>
                    <EsgFeeds data={feedsData} />
                </PlaceholderSection>

                <PlaceholderSection>
                    <Typography.TextH3_24_34>
                        Product updates
                    </Typography.TextH3_24_34>
                    <LatestNews data={latestNewsData} />
                </PlaceholderSection>
            </DashboardPlaceholderWrapper>
        </PlaceholderLayout>
    );
};
