import { useNavigation } from '@speeki/client-admin';
import { Button, TableEmptyState } from '@speeki/global-ui-components';

import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import { EmptySpaceWrapper } from '@pages/StaticPlaceholder.styled';

export const CarbonLensPlaceholder = () => {
    const { navigate } = useNavigation();

    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgEsgCalculator',
                title: 'Carbon Lens',
            }}
        >
            <TableEmptyState testId="szDeDMt" title="Welcome to Carbon Lens">
                <EmptySpaceWrapper>
                    Carbon Lens is used for GHG emission accounting. This
                    feature is currently under development. Please check back
                    later.
                    <Button
                        center
                        onClick={() =>
                            navigate({
                                path: '/dashboard',
                            })
                        }
                        testId="e2yumUP"
                        variant={'primary'}
                    >
                        Back to Homepage
                    </Button>
                </EmptySpaceWrapper>
            </TableEmptyState>
        </PlaceholderLayout>
    );
};
