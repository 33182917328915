import { Typography } from '@speeki/global-ui-components';

import { StatsFrameWrapper, ValueSkeleton } from './StatsFrame.styled';

export interface StatsFrameProps {
    isDisabled?: boolean;
    label: string;
    loading?: boolean;
    value: number;
}

export const StatsFrame = ({
    isDisabled,
    label,
    loading,
    value,
}: StatsFrameProps) => {
    return (
        <StatsFrameWrapper isDisabled={isDisabled}>
            {loading ? (
                <ValueSkeleton height={34} />
            ) : (
                <Typography.TextH3_24_34>{value}</Typography.TextH3_24_34>
            )}
            <Typography.TextBody_10_16>{label}</Typography.TextBody_10_16>
        </StatsFrameWrapper>
    );
};
