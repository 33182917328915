import { ReactNode } from 'react';

import { Typography } from '@speeki/global-ui-components';

import { PlaceholderCard } from '@components/atoms/PlaceholderCard/PlaceholderCard';

import {
    CardContent,
    ChartElement,
    ChartWrapper,
    TrainingCardWrapper,
} from './TrainingCard.styled';

export interface TrainingCardProps {
    chart: string;
    children: ReactNode;
    title: string;
}

export const TrainingCard = ({ chart, children, title }: TrainingCardProps) => {
    return (
        <PlaceholderCard>
            <TrainingCardWrapper>
                <ChartWrapper>
                    <ChartElement alt={title} src={chart} />
                </ChartWrapper>
                <CardContent>
                    <Typography.TextH4_20_28>{title}</Typography.TextH4_20_28>
                    {children}
                </CardContent>
            </TrainingCardWrapper>
        </PlaceholderCard>
    );
};
