import { orderBy } from 'lodash-es';
import { useMemo } from 'react';

import { ESG_SUBCATEGORY } from '@speeki/dictionary';

import {
    DashboardStatisticsDocument,
    EsgStatisticsDocument,
} from '@graphql/generated/graphql';

import { useUserModules } from '@utils/hooks/useUserModules';

import { useQuery } from '@apollo/client';

export type CategoryName = 'environmental' | 'social' | 'governance';

interface ParsedRiskArea {
    categoryName: CategoryName;
    name: string;
    path: string;
    statistics: {
        answeredQuestions: number;
        percent: number;
        totalQuestions: number;
    };
}

export const useStatistics = () => {
    const { hasEsgAccess, hasIncidentsAccess, hasSurveyAccess } =
        useUserModules();

    const { data: statisticsData, loading: statisticsDataLoading } = useQuery(
        DashboardStatisticsDocument,
        {
            context: {
                notifyOnError: true,
            },
            skip: !hasIncidentsAccess && !hasSurveyAccess,
            variables: {
                hasIncidentsAccess,
                hasSurveyAccess,
            },
        },
    );

    const { data: { categories } = {}, loading: esgStatisticsDataLoading } =
        useQuery(EsgStatisticsDocument, {
            skip: !hasEsgAccess,
            variables: {
                includeDisabledRiskAreas: false,
                input: {
                    name: ['ENVIRONMENTAL', 'GOVERNANCE', 'SOCIAL', 'GENERAL'],
                },
            },
        });

    const parsedRiskAreas: ParsedRiskArea[] = useMemo(
        () =>
            categories
                ?.map((category) => ({
                    riskAreas: category.riskAreas.map((riskArea) => {
                        const answeredQuestions =
                            riskArea.statistics?.answeredQuestions ?? 0;

                        const totalQuestions =
                            riskArea.statistics?.totalQuestions ?? 0;
                        return {
                            categoryName:
                                category.name.toLowerCase() as CategoryName,
                            name:
                                riskArea.customLabel ||
                                ESG_SUBCATEGORY[riskArea.name],
                            path: riskArea.path,
                            statistics: {
                                answeredQuestions,
                                percent: Math.floor(
                                    (answeredQuestions / totalQuestions) * 100,
                                ),
                                totalQuestions,
                            },
                        };
                    }),
                }))
                .flatMap((item) => item.riskAreas) ?? [],
        [categories],
    );

    //NOTE: we need 3 elements
    const mostAnsweredRiskAreas = useMemo(
        () =>
            orderBy(parsedRiskAreas, ['statistics.percent'], ['desc']).slice(
                0,
                3,
            ),
        [parsedRiskAreas],
    );

    const incidentsStatisticsData =
        statisticsData?.incidentsStatistics?.statistics;
    const surveysStatisticsData = statisticsData?.surveyStatistics?.statistics;

    const incidentsStatistics = [
        {
            label: 'total incidents',
            value: incidentsStatisticsData?.totalCount || 0,
        },
        {
            label: 'active incident(s)',
            value: incidentsStatisticsData?.openedCount || 0,
        },
        {
            label: 'total incidents closed',
            value: incidentsStatisticsData?.closedCount || 0,
        },
    ];

    const surveyStatistics = [
        {
            label: 'completed surveys',
            value: surveysStatisticsData?.completedCount ?? 0,
        },
        {
            label: 'ongoing surveys',
            value: surveysStatisticsData?.ongoingCount ?? 0,
        },
        {
            label: 'scheduled surveys',
            value: surveysStatisticsData?.scheduledCount ?? 0,
        },
    ];

    return {
        esgStatisticsDataLoading,
        hasEsgAccess,
        hasIncidentsAccess,
        hasSurveyAccess,
        incidentsStatistics,
        mostAnsweredRiskAreas,
        statisticsDataLoading,
        surveyStatistics,
    };
};
