import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const InputWrapper = styled.div(
    ({ theme: { palette, radius } }) => css`
        align-items: center;
        background-color: ${palette.neutral.lightGrey};
        border-radius: ${radius['4']}px;
        border: 2px dashed ${palette.tertiary.disabled};
        display: flex;
        justify-content: center;
        padding: 0 32px;
    `,
);

export const InputText = styled(Typography.TextH5_18_26)(
    ({ theme: { palette } }) => css`
        text-align: center;

        > span {
            color: ${palette.tertiary.default};
            cursor: pointer;

            &:hover {
                color: ${palette.tertiary.hover};
            }
        }
    `,
);
