export interface StarProps {
    fill: string;
}

export const Star = ({ fill }: StarProps) => (
    <svg
        fill="none"
        height="15"
        viewBox="0 0 15 15"
        width="15"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.71468 1.37811C6.80449 1.10172 7.19551 1.10172 7.28532 1.37812L8.50424 5.12959C8.5444 5.25319 8.65959 5.33688 8.78956 5.33688H12.7341C13.0247 5.33688 13.1455 5.70876 12.9104 5.87958L9.71923 8.19812C9.61409 8.27451 9.57009 8.40992 9.61025 8.53353L10.8292 12.285C10.919 12.5614 10.6026 12.7912 10.3675 12.6204L7.17633 10.3019C7.07119 10.2255 6.92881 10.2255 6.82366 10.3019L3.63247 12.6204C3.39736 12.7912 3.08101 12.5614 3.17082 12.285L4.38975 8.53353C4.42991 8.40992 4.38591 8.27451 4.28076 8.19812L1.08957 5.87958C0.854459 5.70876 0.975292 5.33688 1.26591 5.33688H5.21044C5.34041 5.33688 5.45559 5.25319 5.49576 5.12958L6.71468 1.37811Z"
            fill={fill}
        />
    </svg>
);
