import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const TrainingActions = styled.div`
    display: flex;
    gap: 16px;
`;

export const CardsWrapper = styled.div`
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    padding: 28px 0 24px;
`;

export const LegendWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const RatingsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px 16px;
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const ChartLegendWrapper = styled.div(
    ({ theme: { palette } }) => css`
        align-self: flex-end;
        border: 1px solid ${palette.neutral.dividers};
        display: flex;
        gap: 24px;
        padding: 16px 32px;
    `,
);

export const LabelsWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const LabelText = styled(Typography.TextBody_16_24)(
    ({ theme: { fontWeight } }) => css`
        span {
            font-weight: ${fontWeight.bold};
        }
    `,
);
