import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
`;
