import styled from 'styled-components';

export const LatestNewsWrapper = styled.div`
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
