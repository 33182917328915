import { nanoid } from 'nanoid';
import { Column } from 'react-table';

import { TableBlueText } from '@pages/StaticPlaceholder.styled';

export interface AssessmentPlaceholderTableRow {
    description: string;
    id: string;
    pricing: string;
    topic: string;
}

const MIN_SEE_MORE_SIGNS = 125;

export const assessmentPlaceholderHeaderData: Column<AssessmentPlaceholderTableRow>[] =
    [
        {
            Cell: ({ value }) => <TableBlueText>{value}</TableBlueText>,
            Header: 'Topic',
            accessor: 'topic',
            width: 280,
        },
        {
            Cell: ({ value }) => {
                return value.length < MIN_SEE_MORE_SIGNS ? (
                    <div>{value}</div>
                ) : (
                    <div>
                        {value}
                        <TableBlueText>See more</TableBlueText>
                    </div>
                );
            },
            Header: 'Description',
            accessor: 'description',
            disableSortBy: true,
            width: 672,
        },
        {
            Header: 'Pricing',
            accessor: 'pricing',
            disableSortBy: true,
            width: 250,
        },
    ];

export const assessmentPlaceholderTableData: AssessmentPlaceholderTableRow[] = [
    {
        id: nanoid(),
        description:
            'Corruption is a risk to companies due to strong legislation worldwide, such as: FCPA, Bribery Act, Sapin 2, OECD guidelines.',
        pricing: 'starting from US$895',
        topic: 'Bribery & Corruption',
    },
    {
        id: nanoid(),
        description:
            'Just as general society’s awareness of the importance of diversity and inclusion intensifies, so too does the business case for organisations to include diversity and inclusion policies in their... ',
        pricing: 'starting from US$895',
        topic: 'Diversity, Equity & Inclusion',
    },
    {
        id: nanoid(),
        description:
            'A lack of consideration for health and safety can increase absenteeism and negligence claims, affecting workplace productivity and morale. It can also lead to injuries, long-term illnesses... ',
        pricing: 'starting from US$895',
        topic: 'Health & Safety',
    },
    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Whistleblowing supports the reporting of wrongdoing, most frequently discovered at work, to protect the public and the organisation. Whistleblowing complaints include, but are not limited to... ',
        pricing: 'starting from US$895',
        topic: 'Whistleblowing',
    },
    {
        id: nanoid(),
        description:
            'Corruption is a risk to companies due to strong legislation worldwide, such as: FCPA, Bribery Act, Sapin 2, OECD guidelines.',
        pricing: 'starting from US$895',
        topic: 'Bribery & Corruption',
    },
    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Just as general society’s awareness of the importance of diversity and inclusion intensifies, so too does the business case for organisations to include diversity and inclusion policies in their... ',
        pricing: 'starting from US$895',
        topic: 'Diversity, Equity & Inclusion',
    },
    {
        id: nanoid(),
        description:
            'A lack of consideration for health and safety can increase absenteeism and negligence claims, affecting workplace productivity and morale. It can also lead to injuries, long-term illnesses... ',
        pricing: 'starting from US$895',
        topic: 'Health & Safety',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Corruption is a risk to companies due to strong legislation worldwide, such as: FCPA, Bribery Act, Sapin 2, OECD guidelines.',
        pricing: 'starting from US$895',
        topic: 'Bribery & Corruption',
    },
    {
        id: nanoid(),
        description:
            'Just as general society’s awareness of the importance of diversity and inclusion intensifies, so too does the business case for organisations to include diversity and inclusion policies in their... ',
        pricing: 'starting from US$895',
        topic: 'Diversity, Equity & Inclusion',
    },
    {
        id: nanoid(),
        description:
            'A lack of consideration for health and safety can increase absenteeism and negligence claims, affecting workplace productivity and morale. It can also lead to injuries, long-term illnesses... ',
        pricing: 'starting from US$895',
        topic: 'Health & Safety',
    },
    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Corruption is a risk to companies due to strong legislation worldwide, such as: FCPA, Bribery Act, Sapin 2, OECD guidelines.',
        pricing: 'starting from US$895',
        topic: 'Bribery & Corruption',
    },
    {
        id: nanoid(),
        description:
            'Just as general society’s awareness of the importance of diversity and inclusion intensifies, so too does the business case for organisations to include diversity and inclusion policies in their... ',
        pricing: 'starting from US$895',
        topic: 'Diversity, Equity & Inclusion',
    },
    {
        id: nanoid(),
        description:
            'A lack of consideration for health and safety can increase absenteeism and negligence claims, affecting workplace productivity and morale. It can also lead to injuries, long-term illnesses... ',
        pricing: 'starting from US$895',
        topic: 'Health & Safety',
    },
    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Whistleblowing supports the reporting of wrongdoing, most frequently discovered at work, to protect the public and the organisation. Whistleblowing complaints include, but are not limited to... ',
        pricing: 'starting from US$895',
        topic: 'Whistleblowing',
    },
    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },

    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Whistleblowing supports the reporting of wrongdoing, most frequently discovered at work, to protect the public and the organisation. Whistleblowing complaints include, but are not limited to... ',
        pricing: 'starting from US$895',
        topic: 'Whistleblowing',
    },
    {
        id: nanoid(),
        description:
            'Corruption is a risk to companies due to strong legislation worldwide, such as: FCPA, Bribery Act, Sapin 2, OECD guidelines.',
        pricing: 'starting from US$895',
        topic: 'Bribery & Corruption',
    },
    {
        id: nanoid(),
        description:
            'Human capital management (HCM) is the set of practices an organisation uses for recruiting, managing, developing and optimising employees to increase their value to the company. In addition... ',
        pricing: 'starting from US$895',
        topic: 'Human Capital Management',
    },
    {
        id: nanoid(),
        description:
            'Respecting and supporting human rights remains one of the most challenging areas of corporate sustainability. Yet, in an increasingly interconnected world with closer scrutiny of corporate... ',
        pricing: 'starting from US$895',
        topic: 'Human Rights & Modern Day Slavery',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Risk managers know the purpose of their roles and the value they bring to their organisations. However, other employees may not understand what their risk department does or the widespread... ',
        pricing: 'starting from US$895',
        topic: 'Risk Management',
    },
    {
        id: nanoid(),
        description:
            'Whistleblowing supports the reporting of wrongdoing, most frequently discovered at work, to protect the public and the organisation. Whistleblowing complaints include, but are not limited to... ',
        pricing: 'starting from US$895',
        topic: 'Whistleblowing',
    },
    {
        id: nanoid(),
        description:
            'Corruption is a risk to companies due to strong legislation worldwide, such as: FCPA, Bribery Act, Sapin 2, OECD guidelines.',
        pricing: 'starting from US$895',
        topic: 'Bribery & Corruption',
    },
];
