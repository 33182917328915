import { ReactNode, useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';

import {
    DefaultLayoutSkeleton,
    Paths,
    useCheckIsLoggedIn,
    usePermissionsCheck,
} from '@speeki/client-admin';
import { useFeatureFlagsStatus } from '@speeki/feature-flags';
import { useEffectOnMountUnsafe } from '@speeki/react-hooks';

import { getDecodedToken } from '@utils/helpers';

import { authService } from '@services/AuthService/AuthService';
import { sentry } from '@services/SentryService/SentryService';

interface AppLoadingProps {
    children: ReactNode;
}

/**
 * Add to this component only async stuff that is required for the proper working of the app.
 */
export const AppLoading = ({ children }: AppLoadingProps) => {
    const { permissionsCheck } = usePermissionsCheck();
    const { flagsReady } = useFeatureFlagsStatus();

    const [initialAppLoading, setInitialAppLoading] = useState(true);

    const { checkIsLoggedIn, checkIsLoggedInLoading } =
        // @ts-expect-error FIXME: instance class type
        useCheckIsLoggedIn(authService, {
            onAfterUserQuery: () => {
                void sentry.setUser();
            },
        });

    const match = (path: Paths) => !!matchPath(path, window.location.pathname);
    const isHomepage = match('/dashboard');

    const isDemoUser = permissionsCheck(['DEMO_FEATURES']);

    const loading = !flagsReady || checkIsLoggedInLoading;

    const redirectToDashboard = !loading && !isDemoUser && !isHomepage;

    useEffectOnMountUnsafe(() => {
        void checkIsLoggedIn();
    });

    useEffect(() => {
        if (loading || !initialAppLoading) return;

        const decodedToken = getDecodedToken();
        const isExternal = decodedToken?.user.type === 'EXTERNAL';

        if (!decodedToken) return;

        if (isExternal) {
            window.location.href = window.location.origin + '/tasks';
            return;
        }

        if (redirectToDashboard)
            window.location.href = window.location.origin + '/dashboard';

        setInitialAppLoading(false);
    }, [redirectToDashboard, loading]);

    if (redirectToDashboard) return <DefaultLayoutSkeleton />;

    return (
        <>
            {loading || initialAppLoading ? (
                <DefaultLayoutSkeleton />
            ) : (
                children
            )}
        </>
    );
};
