import styled from 'styled-components';

export const TrainingCardWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 24px;
    padding: 16px;
`;

export const ChartWrapper = styled.div`
    width: 92px;
    height: 92px;
    position: relative;
`;

export const ChartElement = styled.img`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
