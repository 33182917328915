import styled, { css } from 'styled-components';

import { IconManager } from '@speeki/icons';

export const ArrowIcon = styled(IconManager)``;

export const CardLinkWrapper = styled.a(
    ({ theme: { fontSize, palette } }) => css`
        align-items: center;
        color: ${palette.main.tertiary};
        display: flex;
        font-size: ${fontSize[14]}px;
        text-decoration: none;

        &:hover {
            color: ${palette.tertiary.hover};
        }
    `,
);
