import { Skeleton } from '@speeki/global-ui-components';

import {
    LabelCard,
    LabelCardProps,
} from '@components/molecules/LabelCard/LabelCard';

import { LatestNewsWrapper } from './LatestNews.styled';

interface LatestNewsProps {
    data: LabelCardProps[];
}

export const LatestNews = ({ data }: LatestNewsProps) => {
    if (!data.length)
        return (
            <LatestNewsWrapper>
                {Array(2)
                    .fill(0)
                    .map((_, index) => (
                        <Skeleton
                            height={220}
                            key={index}
                            variant={'inverted'}
                            width={'100%'}
                        />
                    ))}
            </LatestNewsWrapper>
        );

    return (
        <LatestNewsWrapper>
            {data.map((props, index) => (
                <LabelCard key={index} {...props} />
            ))}
        </LatestNewsWrapper>
    );
};
