import { ReactNode } from 'react';

import { IconManager, IconManagerProps } from '@speeki/icons';

import { CardLabelWrapper } from './CardLabel.styled';

export interface CardLabelProps {
    children: ReactNode;
    iconProps?: IconManagerProps;
}

export const CardLabel = ({ children, iconProps }: CardLabelProps) => {
    return (
        <CardLabelWrapper>
            {iconProps && <IconManager {...iconProps} />}
            {children}
        </CardLabelWrapper>
    );
};
