import { __DEV_LOCAL__ } from '@speeki/client-admin';
import { resolveEnv } from '@speeki/frontend-utils';
import {
    LocalDevelopmentOverlay,
    LocalDevelopmentOverlayProps,
} from '@speeki/global-ui-components';
import { StorageManager } from '@speeki/storage-manager';

export const LocalDevelopment = ({
    children,
}: Pick<LocalDevelopmentOverlayProps, 'children'>) => {
    return !__DEV_LOCAL__ ? (
        <>{children}</>
    ) : (
        <LocalDevelopmentOverlay
            required={[
                {
                    condition: !!resolveEnv('REACT_APP_GRAPHQL'),
                    name: 'REACT_APP_GRAPHQL [env]',
                },
                {
                    condition: !!resolveEnv('REACT_APP_UNLEASH_API_URL'),
                    name: 'REACT_APP_UNLEASH_API_URL [env]',
                },
                {
                    condition: !!resolveEnv('REACT_APP_UNLEASH_APP_NAME'),
                    name: 'REACT_APP_UNLEASH_APP_NAME [env]',
                },
                {
                    condition: !!resolveEnv('REACT_APP_UNLEASH_CLIENT_KEY'),
                    name: 'REACT_APP_UNLEASH_CLIENT_KEY [env]',
                },
                {
                    condition: !!resolveEnv(
                        'REACT_APP_UNLEASH_POOLING_INTERVAL',
                    ),
                    name: 'REACT_APP_UNLEASH_POOLING_INTERVAL [env]',
                },
                {
                    condition: !!StorageManager.getValue('graphSessionToken'),
                    name: 'graphSessionToken [localstorage]',
                },
                {
                    condition: !!StorageManager.getValue('graphRefreshToken'),
                    name: 'graphRefreshToken [localstorage]',
                },
                {
                    condition: !!StorageManager.getValue('graphqlUrl'),
                    name: 'graphqlUrl [localstorage]',
                },
            ]}
        >
            {children}
        </LocalDevelopmentOverlay>
    );
};
