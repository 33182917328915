import { AuthService, LogoutOptions } from '@speeki/client-admin';

import { sentry } from '@services/SentryService/SentryService';

import { client } from '@boot/apolloClient';
import { resetVars } from '@boot/reactiveVariables';

const service = new AuthService(resetVars, sentry);

export const authService = {
    ...service,
    logout: (options?: LogoutOptions) => service.logout(client, options),
};
