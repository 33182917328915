import { caTypePolices } from '@speeki/client-admin';

import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
    dataIdFromObject: (responseObject) => {
        if (responseObject.uuid && typeof responseObject.uuid === 'string') {
            return `${responseObject.__typename}:${responseObject.uuid}`;
        }

        return defaultDataIdFromObject(responseObject);
    },
    typePolicies: caTypePolices.typePolicies,
});
