import { AnchorHTMLAttributes, ReactNode, useRef } from 'react';

import { useIconState } from '@speeki/icons';
import { useHover } from '@speeki/react-hooks';

import { ArrowIcon, CardLinkWrapper } from './CardLink.styled';

export interface CardLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    children: ReactNode;
}

export const CardLink = ({ children, ...props }: CardLinkProps) => {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const hover = useHover(linkRef);
    const { iconState } = useIconState(hover.value);

    return (
        <CardLinkWrapper {...props} ref={linkRef}>
            {children}
            <ArrowIcon
                icon={'SvgChevronRight24'}
                iconState={iconState}
                size={24}
                themeColors={{
                    default: {
                        'color-1': 'main.tertiary',
                    },
                    hover: {
                        'color-1': 'tertiary.hover',
                    },
                }}
            />
        </CardLinkWrapper>
    );
};
