import { Skeleton } from '@speeki/global-ui-components';

import { EsgContentWrapper } from './EsgArticlesAndFeeds.styled';

export const EsgArticlesAndFeedsSkeleton = () => {
    return (
        <EsgContentWrapper>
            {Array(6)
                .fill(0)
                .map((_, index) => (
                    <Skeleton
                        height={248}
                        key={index}
                        variant={'inverted'}
                        width={'100%'}
                    />
                ))}
        </EsgContentWrapper>
    );
};
