import { useTheme } from 'styled-components';

import { useStatistics } from './useStatistics';

import { getPath, useNavigation } from '@speeki/client-admin';
import { Button, Skeleton } from '@speeki/global-ui-components';

import { CardLink } from '@components/atoms/CardLink/CardLink';
import { PlaceholderCard } from '@components/atoms/PlaceholderCard/PlaceholderCard';
import { StatsFrame } from '@components/atoms/StatsFrame/StatsFrame';
import { QuestionnaireStatistic } from '@components/molecules/QuestionnaireStatistic/QuestionnaireStatistic';

import {
    ActionWrapper,
    ProgressWrapper,
    StatisticHeader,
    StatisticsWrapper,
    StatsFramesWrapper,
} from './Statistics.styled';

export const Statistics = () => {
    const { navigate } = useNavigation();
    const { palette } = useTheme();

    const {
        esgStatisticsDataLoading,
        hasEsgAccess,
        hasIncidentsAccess,
        hasSurveyAccess,
        incidentsStatistics,
        mostAnsweredRiskAreas,
        statisticsDataLoading,
        surveyStatistics,
    } = useStatistics();

    return (
        <StatisticsWrapper>
            <PlaceholderCard hidden={!hasEsgAccess}>
                <StatisticHeader>
                    <PlaceholderCard.Title>
                        Your ESG Programmes
                    </PlaceholderCard.Title>
                    <PlaceholderCard.Description>
                        Track your programmes completion and progress.
                    </PlaceholderCard.Description>
                </StatisticHeader>
                <ProgressWrapper>
                    {esgStatisticsDataLoading
                        ? Array(3)
                              .fill(0)
                              .map((_, index) => (
                                  <Skeleton
                                      height={52}
                                      key={index}
                                      width={'100%'}
                                  />
                              ))
                        : mostAnsweredRiskAreas.map((riskArea) => (
                              <QuestionnaireStatistic
                                  key={riskArea.name}
                                  link={getPath({
                                      params: {
                                          questionnaire: riskArea.path,
                                      },
                                      path: '/esg/:questionnaire',
                                  })}
                                  loading={!mostAnsweredRiskAreas.length}
                                  progressIndicator={{
                                      backgroundColor: palette.neutral.gray,
                                      progressColor:
                                          palette.esg[riskArea.categoryName],
                                  }}
                                  statistics={{
                                      allQuestions:
                                          riskArea.statistics.totalQuestions,
                                      filledQuestions:
                                          riskArea.statistics.answeredQuestions,
                                  }}
                                  title={riskArea.name}
                              />
                          ))}
                </ProgressWrapper>
                <ActionWrapper>
                    <CardLink
                        href={getPath({
                            path: '/esg',
                        })}
                    >
                        Manage your programmes
                    </CardLink>
                </ActionWrapper>
            </PlaceholderCard>

            <PlaceholderCard hidden={!hasIncidentsAccess}>
                <StatisticHeader>
                    <PlaceholderCard.Title>Incidents</PlaceholderCard.Title>
                    <PlaceholderCard.Description>
                        Summary of your incidents statuses.
                    </PlaceholderCard.Description>
                </StatisticHeader>
                <StatsFramesWrapper>
                    {incidentsStatistics.map((item) => (
                        <StatsFrame
                            key={item.label}
                            loading={statisticsDataLoading}
                            {...item}
                        />
                    ))}
                </StatsFramesWrapper>
                <ActionWrapper>
                    <CardLink
                        href={getPath({
                            path: '/incidents/dashboard',
                        })}
                    >
                        Go to Incidents
                    </CardLink>
                </ActionWrapper>
            </PlaceholderCard>

            <PlaceholderCard hidden={!hasSurveyAccess}>
                <StatisticHeader>
                    <PlaceholderCard.Title>Surveys</PlaceholderCard.Title>
                    <PlaceholderCard.Description>
                        Summary of your surveys statuses.
                    </PlaceholderCard.Description>
                </StatisticHeader>
                <StatsFramesWrapper>
                    {surveyStatistics.map((item) => (
                        <StatsFrame
                            key={item.label}
                            loading={statisticsDataLoading}
                            {...item}
                        />
                    ))}
                </StatsFramesWrapper>
                <ActionWrapper>
                    <Button
                        fullWidth
                        onClick={() => {
                            navigate({
                                path: '/survey/templates/create',
                            });
                        }}
                        testId="I6D4s2O"
                        variant="secondary"
                    >
                        Set up and send your survey
                    </Button>
                </ActionWrapper>
            </PlaceholderCard>
        </StatisticsWrapper>
    );
};
