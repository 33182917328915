import styled from 'styled-components';
import { css } from 'styled-components';

export const StatusWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;

export const StatusColor = styled.div<{ color: string }>(
    ({ color, theme: { radius } }) => css`
        background-color: ${color};
        border-radius: ${radius['6']}px;
        height: 6px;
        width: 6px;
    `,
);

export const IconWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const SearchFieldWrapper = styled.div`
    min-width: 424px;
`;
