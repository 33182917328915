import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const PlaceholderCardWrapper = styled.div<{ hidden?: boolean }>(
    ({ hidden, theme: { boxShadow, palette, radius } }) => css`
        background-color: ${palette.neutral.white};
        border-radius: ${radius[4]}px;
        box-shadow: ${boxShadow.small};
        display: flex;
        flex-direction: column;
        padding: 24px;

        ${hidden &&
        css`
            display: none;
        `}
    `,
);

export const PlaceholderCardTitle = styled(Typography.TextH4_20_28)``;

export const PlaceholderCardDescription = styled(Typography.TextBody_14_20)``;

export const PlaceholderCardGrayText = styled(Typography.TextBody_12_18)(
    ({ theme: { palette } }) => css`
        color: ${palette.text.assistive};
    `,
);

export const BoldText = styled.span(
    ({ theme: { fontWeight } }) => css`
        font-weight: ${fontWeight.bold};
    `,
);

export const RedText = styled.span(
    ({ theme: { palette } }) => css`
        color: ${palette.semantic.error};
    `,
);
