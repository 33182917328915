import { client } from '../boot/apolloClient';
import { createUploadLink } from 'apollo-upload-client';

import {
    createAuthLink,
    createLogoutLink,
    createRefreshLink,
    createReplaceTokensLink,
} from '@speeki/apollo-utils';
import { createErrorLink, CreateRefreshLink } from '@speeki/apollo-utils';
import { logoutProcessingVar } from '@speeki/client-admin';
import { TOKEN_EXPIRED } from '@speeki/dictionary';
import { createSentryLink } from '@speeki/frontend-sentry';
import { resolveEnv } from '@speeki/frontend-utils';
import { notify } from '@speeki/global-ui-components';
import { StorageManager } from '@speeki/storage-manager';

import { RefreshSessionDocument } from '@graphql/generated/graphql';

import { authService } from '@services/AuthService/AuthService';
import { sentry } from '@services/SentryService/SentryService';

import { ApolloLink, from } from '@apollo/client';

const headers = { 'Apollo-Require-Preflight': 'true' };

export const directionalLink = ApolloLink.split(
    (operation) => operation.getContext().globalUrl,
    //@ts-expect-error FIXME
    createUploadLink({
        headers,
        uri: (operation) =>
            `${resolveEnv('REACT_APP_GRAPHQL')}?${operation.operationName}`,
    }),
    createUploadLink({
        headers,
        uri: (operation) =>
            `${StorageManager.getValue('graphqlUrl') || ''}?${
                operation.operationName
            }`,
    }),
);

export const fetchNewSessionToken: CreateRefreshLink['fetchNewSessionToken'] = (
    refreshToken,
) =>
    client
        .mutate({
            mutation: RefreshSessionDocument,
            variables: {
                token: refreshToken,
            },
        })
        .then((res) => {
            const data = res.data?.refreshSession;

            data?.sessionToken &&
                StorageManager.setValue('graphSessionToken', data.sessionToken);
            data?.refreshToken &&
                StorageManager.setValue('graphRefreshToken', data.refreshToken);
        });

const allowedErrors = [TOKEN_EXPIRED];

export const errorLink = createErrorLink({
    allowedErrors,
    onNotify: (message) => notify(message).ERROR(),
});

export const links = from([
    createRefreshLink({
        fetchNewSessionToken,
        getRefreshToken: () => StorageManager.getValue('graphRefreshToken'),
        onRefreshError: () => {
            authService.logout();
        },
        onTokenExpiredNoRefresh: () => {
            authService.logout();
        },
    }),
    createLogoutLink({
        onLogout: () => {
            if (!logoutProcessingVar()) {
                authService.logout({ logoutWithRedirectLink: true });
            }
        },
    }),
    createReplaceTokensLink({
        getRefreshToken: () => StorageManager.getValue('graphRefreshToken'),
        setRefreshToken: (token) =>
            StorageManager.setValue('graphRefreshToken', token),
        setSessionToken: (token) => {
            StorageManager.setValue('graphSessionToken', token);
            sentry.setUser();
        },
    }),
    createAuthLink({
        getSessionToken: () => StorageManager.getValue('graphSessionToken'),
    }),
    errorLink,
    createSentryLink(sentry),
    directionalLink,
]);
