import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const QuestionnaireStatisticWrapper = styled.div``;

// HEADER
export const QuestionnaireStatisticHeader = styled.div`
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

// CONTENT
export const QuestionnaireStatisticContent = styled.div`
    align-items: center;
    display: flex;
    gap: 0 16px;
`;

export const QuestionnaireStatisticInfo = styled(Typography.TextBody_14_20)(
    ({ theme: { fontWeight, palette } }) => css`
        color: ${palette.neutral.darkGrey};
        flex-shrink: 0;
        font-weight: ${fontWeight.normal};
        text-align: right;
        white-space: nowrap;
        width: 100px;
    `,
);

export const LinkWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    width: 100px;
`;
