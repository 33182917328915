import { useNavigation } from '@speeki/client-admin';
import { Button, TableEmptyState } from '@speeki/global-ui-components';

import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import { EmptySpaceWrapper } from '@pages/StaticPlaceholder.styled';

export const DueDiligencePlaceholder = () => {
    const { navigate } = useNavigation();

    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgDueDiligence',
                title: 'Due Diligence',
            }}
        >
            <TableEmptyState testId="szDeDMt" title="Welcome to Speeki Search">
                <EmptySpaceWrapper>
                    This feature is currently under development. Please check
                    back later.
                    <Button
                        center
                        onClick={() =>
                            navigate({
                                path: '/dashboard',
                            })
                        }
                        testId="Y_KSxzV"
                        variant={'primary'}
                    >
                        Back to Homepage
                    </Button>
                </EmptySpaceWrapper>
            </TableEmptyState>
        </PlaceholderLayout>
    );
};
