import { InputText, InputWrapper } from './DragAndDropInput.styled';

interface DragAndDropInputProps {
    text: string;
}

export const DragAndDropInput = ({ text }: DragAndDropInputProps) => {
    return (
        <InputWrapper>
            <InputText>
                Drag and drop your {text} or <span>select a file</span> to
                upload.
            </InputText>
        </InputWrapper>
    );
};
