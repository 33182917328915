import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const TooltipWrapper = styled.div(
    ({ theme: { palette, radius } }) => css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 16px;
        background-color: ${palette.text.secondary};
        border-radius: ${radius[6]}px;
    `,
);

export const TooltipTitle = styled(Typography.TextBody_12_18)(
    ({ theme: { fontWeight, palette } }) => css`
        color: ${palette.neutral.white};
        font-weight: ${fontWeight.bold};
    `,
);

export const TooltipText = styled(Typography.TextBody_12_18)(
    ({ theme: { palette } }) => css`
        color: ${palette.neutral.white};
    `,
);
