import styled, { css } from 'styled-components';

export const DashboardPlaceholderWrapper = styled.div(
    ({ theme: { palette } }) => css`
        background-color: ${palette.tertiary.background};
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin: -48px -24px;
        padding: 48px 24px 48px;
    `,
);
