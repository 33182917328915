import {
    certificatesPlaceholderHeaderData,
    certificatesPlaceholderTableData,
    CertificatesPlaceholderTableRow,
} from './CertificatesPlaceholder.settings';

import { Button, DefaultTable, Typography } from '@speeki/global-ui-components';

import { ObtainedCertifications } from '@components/organisms/ObtainedCertifications/ObtainedCertifications';
import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    ContentHeader,
    ContentHeaderText,
    ContentWrapper,
    PlaceholderSection,
    TableWrapper,
} from '@pages/StaticPlaceholder.styled';

export const CertificatesPlaceholder = () => {
    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgCertificates',
                title: 'Assurance',
            }}
        >
            <ContentWrapper gap={'48px'}>
                <ContentHeader>
                    <ContentHeaderText>
                        Explore Speeki's Assurance Services or add reports or
                        certifications to your profile.
                    </ContentHeaderText>
                    <Button testId="fY_qbj6" variant="primary">
                        Upload new certification
                    </Button>
                </ContentHeader>

                <PlaceholderSection gap={'32px'}>
                    <Typography.TextH3_24_34>
                        Obtained certifications
                    </Typography.TextH3_24_34>

                    <ObtainedCertifications />
                </PlaceholderSection>

                <TableWrapper>
                    <Typography.TextH3_24_34>
                        Speeki's Certification Services
                    </Typography.TextH3_24_34>
                    <div>
                        <DefaultTable<CertificatesPlaceholderTableRow>
                            columns={certificatesPlaceholderHeaderData}
                            data={certificatesPlaceholderTableData}
                            pagination={{
                                options: {
                                    defaultPageSize: 4,
                                    totalCount:
                                        certificatesPlaceholderTableData.length,
                                },
                            }}
                            tableRow={{
                                onRowClick: () => {},
                                showRightArrow: true,
                                testId: '2MazLjr',
                            }}
                            tableState={{
                                hiddenColumns: ['id'],
                            }}
                            testId="OHcHjGe"
                        />
                    </div>
                </TableWrapper>
            </ContentWrapper>
        </PlaceholderLayout>
    );
};
